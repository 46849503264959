import { Icon } from '@vilibets/vilibets-uikit/dist/atoms/Icon';
import { InfoCircle } from '@vilibets/vilibets-uikit/dist/atoms/Icons';
import { Button } from '@vilibets/vilibets-uikit/dist/atoms/Button';
import { CalendarInput } from '@vilibets/vilibets-uikit/dist/atoms/CalendarInput/CalendarInput';
import { Popup } from '@vilibets/vilibets-uikit/dist/atoms/Popup/Popup';
import { Select } from '@vilibets/vilibets-uikit/dist/atoms/Select/Select';
import { Checkbox } from '@vilibets/vilibets-uikit/dist/atoms/Checkbox';
import { Input } from '@vilibets/vilibets-uikit/dist/atoms/Input';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import getRates from '../../services/rates';
import getPaymentMethods from '../../services/paymentMethods';
import { createManualSubscription, updateSubscription } from '../../services/subscriptions';

const UserSubscriptionDetailPopup = ({ display, closePopup, user }) => {
  const [fields, setField] = useState();
  const [rates, setRates] = useState();
  const [resetSelector, setResetSelector] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState();

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      // Get rates
      async function fetchRatesFunction() {
        const response_rates = await getRates();
        const rates = [];
        response_rates.forEach((element) => {
          rates.push({
            value: element.id,
            label: element.title,
            total: element.total,
            type: element.type,
          });
        });
        setRates(rates);

        // Get payment methods
        const response_methods = await getPaymentMethods();
        const paymentMethods = [];
        response_methods.forEach((element) => {
          if (parseInt(element.is_active)) {
            paymentMethods.push({
              value: element.id,
              label: element.description,
              discount: element.discount,
            });
          }
        });

        setPaymentMethods(paymentMethods);
      }
      fetchRatesFunction();
    }
    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    if (user) {
      if (user.subscription === null) {
        setField({
          start_date: '',
          end_date: '',
          rate_id: '',
          affiliate_invitation_counter: 0,
          user_id: user.id,
          order_id: '',
          payment_method_id: '',
          total: null,
          create_order: true,
        });
      } else {
        setField({
          id: user.subscription.id,
          start_date: user.subscription.start_date,
          end_date: user.subscription.end_date,
          rate_id: user.subscription.rate_id,
          affiliate_invitation_counter: user.subscription.affiliate_invitation_counter,
          user_id: user.subscription.user_id,
          order_id: user.subscription?.paypal_id ? null : user.subscription.last_order?.id,
          payment_method_id: user.subscription.last_order?.payment_method_id,
          total: user.subscription.last_order?.total,
          create_order: !!user.subscription.last_order?.id && !user.subscription?.paypal_id,
        });
      }
    }
  }, [user]);

  return (
    fields &&
    user && (
      <Popup display={display} width={'md:min-w-[40%]'}>
        <div className="flex items-start justify-between p-5 rounded-t">
          <section className="flex flex-row items-end">
            <h3 className="text-xl font-semibold dark:text-accent-dark">Suscripción de {user?.name}</h3>
            <section className="px-3 flex-auto mb-1.5">
              {user.subscription ? (
                <div className="flex flex-row">
                  <h3 className="w-fit text-[10px] font-semibold dark:text-accent-dark bg-secondary-4 dark:bg-primary-2 rounded-md px-1.5">
                    Tiene suscripción
                  </h3>
                </div>
              ) : (
                <div className="">
                  <h3 className="w-fit text-[10px] font-semibold dark:text-accent-dark bg-black-4 dark:bg-primary-2 rounded-md px-2.5">
                    No tiene suscripción
                  </h3>
                </div>
              )}
            </section>
          </section>
          <button className="p-1 ml-auto bg-transparent border-0 text-black-7 float-right text-3xl outline-none focus:outline-none">
            <span
              onClick={() => closePopup()}
              className="bg-transparent text-black-7 h-6 w-6 text-2xl block outline-none focus:outline-none items-center leading-none font-thin">
              ×
            </span>
          </button>
        </div>
        <section className="child:mb-2">
          <div className="flex flex-row gap-3 child:w-1/2 pl-5 pr-5">
            <section className="grid grid-cols-3 gap-3">
              <div className="col-span-2">
                <Select
                  label="Tarifa"
                  name="rate_id"
                  type="small"
                  width="w-auto"
                  borderWidth="border-2"
                  value={fields.rate_id}
                  placeholderMessage="Seleccione una tarifa"
                  initialValue={rates.find((option) => option.value === fields.rate_id)}
                  action={(e) => {
                    if (!user.subscription?.paypal_id) {
                      let total = rates.find((o) => o.value === e.value).total;
                      let discount = 0;
                      if (fields?.payment_method_id) {
                        discount = paymentMethods.find((option) => option.value === fields.payment_method_id).discount;
                        total = total - (total * discount) / 100;
                      }
                      setField({ ...fields, rate_id: e.value, total });
                    }
                  }}
                  options={rates}
                  disabled={!fields.create_order || user.subscription?.paypal_id}
                />
              </div>
              <div className="col-1">
                <Input
                  id="total"
                  width="w-auto"
                  placeholderMessage="Importe"
                  name="total"
                  type={'number'}
                  value={fields.total}
                  onChange={(e) => setField({ ...fields, total: e.target.value })}
                  step="0.01"
                  disabled={!fields.create_order || user.subscription?.paypal_id}
                />
              </div>
            </section>
            <Select
              label="Método de pago"
              name="payment_method_id"
              type="small"
              width="w-auto"
              borderWidth="border-2"
              value={fields.payment_method_id}
              placeholderMessage="Seleccione un método de pago"
              initialValue={paymentMethods.find((option) => option.value === fields.payment_method_id)}
              action={(e) => {
                if (!user.subscription?.paypal_id) {
                  let total = fields?.total;
                  let discount = 0;
                  if (fields?.rate_id) {
                    total = rates.find((o) => o.value === fields.rate_id).total;
                    discount = paymentMethods.find((option) => option.value === e.value).discount;
                    total = total - (total * discount) / 100;
                  }
                  setField({ ...fields, payment_method_id: e.value, total });
                }
              }}
              options={paymentMethods}
              resetSelector={resetSelector}
              disabled={!fields.create_order || user.subscription?.paypal_id}
            />
          </div>
          <div className="flex flex-col gap-3 pl-5 pr-5">
            {!user.subscription?.paypal_id && (
              <Checkbox
                defaultChecked={fields.create_order}
                name="create_order"
                action={(e, a) => {
                  setField({ ...fields, create_order: a, payment_method_id: a ? fields.payment_method_id : '' });
                  setResetSelector(!resetSelector);
                }}>
                Generar historial de facturación
              </Checkbox>
            )}
            {user.subscription?.paypal_id && (
              <div htmlFor="warning_paypal" className="flex flex-row text-sm text-warning-red">
                <Icon className="stroke-warning-red text-2xl">
                  <InfoCircle />
                </Icon>
                <span>&nbsp;Suscripción Paypal. Solo se pueden modificar fechas.</span>
              </div>
            )}
            {fields.order_id && !fields.create_order && (
              <div htmlFor="warning_delete_order" className="flex flex-row text-sm text-warning-red">
                <Icon className="stroke-warning-red text-2xl">
                  <InfoCircle />
                </Icon>
                <span>&nbsp;Se eliminará el registro de facturación de la suscripción.</span>
              </div>
            )}
            {fields.rate_id &&
              !user?.subscription &&
              rates.find((o) => o.value === fields.rate_id).type === 'course' &&
              (user?.course ? (
                <div htmlFor="warning_delete_order" className="flex flex-row text-sm text-warning-red">
                  <Icon className="stroke-warning-red text-2xl">
                    <InfoCircle />
                  </Icon>
                  <span>&nbsp;Tarifa no permitida. El usuario ya tiene curso habilitado.</span>
                </div>
              ) : (
                <div htmlFor="warning_delete_order" className="flex flex-row text-sm text-warning-red">
                  <Icon className="stroke-warning-red text-2xl">
                    <InfoCircle />
                  </Icon>
                  <span>&nbsp;Se habilitará el acceso al Curso.</span>
                </div>
              ))}
          </div>
          <div className="flex flex-row p-5 justify-center items-center mb-7">
            <section id="start" className="flex flex-col mr-5">
              <div
                htmlFor="start_date"
                className="text-center bg-black-2 p-3.5 rounded-t-lg dark:text-accent-dark dark:bg-primary-1">
                Fecha de inicio
              </div>
              <CalendarInput
                time={false}
                placeholder="Seleccione inicio de suscripción"
                initialDate={user.subscription ? new Date(fields.start_date) : null}
                inputClass={
                  'drop-shadow-md h-12 items-center border-none font-normal text-black-7 pl-10 cursor-pointer dark:bg-primary-1'
                }
                iconClass={'top-[30%]'}
                action={(e) => {
                  setField({ ...fields, start_date: moment(e).format('YYYY-MM-DD') });
                }}
              />
            </section>
            <section id="end" className="flex flex-col">
              <div
                htmlFor="end_date"
                className="text-center bg-black-2 p-3.5 rounded-t-lg dark:text-accent-dark dark:bg-primary-1">
                Fecha de fin
              </div>
              <CalendarInput
                time={false}
                placeholder="Seleccione fin de suscripción"
                initialDate={user.subscription ? new Date(fields.end_date) : null}
                minDate={fields.start_date !== '' ? new Date(fields.start_date) : null}
                inputClass={
                  'drop-shadow-md h-12 items-center border-none font-normal text-black-7 pl-10 cursor-pointer dark:bg-primary-1'
                }
                iconClass={'top-[30%]'}
                action={(e) => {
                  setField({ ...fields, end_date: moment(e).format('YYYY-MM-DD') });
                }}
              />
            </section>
          </div>
          <Button
            size={'medium'}
            action={async () => {
              if (user.subscription) {
                await updateSubscription(fields.user_id, fields);
                toast.success('Suscripción actualizada', {
                  icon: '🤙',
                });
              } else {
                await createManualSubscription(fields.user_id, fields);
                toast.success('Suscripción creada', {
                  icon: '🤙',
                });
              }
              closePopup();
            }}
            disabled={
              fields.start_date === '' ||
              fields.end_date === '' ||
              fields.rate_id === '' ||
              (rates.find((o) => o.value === fields.rate_id).type === 'course' &&
                user?.course &&
                !user?.subscription) ||
              (fields.create_order && (!fields.payment_method_id || !fields.total || fields.total < 0))
            }
            className="mx-auto mb-10">
            {user.subscription ? 'Actualizar datos de la suscripción' : 'Crear suscripción'}
          </Button>
        </section>
      </Popup>
    )
  );
};

export default UserSubscriptionDetailPopup;
